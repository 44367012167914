// import { FirebaseOptions } from 'firebase/app';

enum BSENV {
    prod = 'prod',
    qa = 'qa',
}

interface Config {
    SAMPLE_APP_SERVICE_URL: string
    SAMPLE_APP_SERVICE_SOCKET_URL: string
}

// Current ENV
// const env: BSENV = BSENV.qa;
const env: BSENV = BSENV.prod;

const config_qa: Config = {
    SAMPLE_APP_SERVICE_URL: 'https://drxzf8gqssd1u.cloudfront.net',
    SAMPLE_APP_SERVICE_SOCKET_URL: 'wss://drxzf8gqssd1u.cloudfront.net'
};

const config_prod: Config = {
    SAMPLE_APP_SERVICE_URL: 'https://d23v33neo3emuy.cloudfront.net',
    SAMPLE_APP_SERVICE_SOCKET_URL: 'wss://d23v33neo3emuy.cloudfront.net'
};

let selected_config: Config;
switch (env as BSENV) {
    case BSENV.qa:
        selected_config = config_qa;
        break; 
    case BSENV.prod:
        selected_config = config_prod;
        break; 
}

let config = selected_config;
export default config;
